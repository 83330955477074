import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./result.module.css";
import maleModel from "../images/male_model.png"; // Import the male model image file
import femaleModel from "../images/Female.png";
import neck from "../images/Vector8.svg";
import chest from "../images/Vector9.svg";
import waist from "../images/Vector10.svg";
import hip from "../images/Vector11.svg";
import male_mob from "../images/dummy_model.png";
import female_mob from "../images/female_mob.png";
import MyPdf from "./ReactPDF/MyPdf.js";
import { PDFDownloadLink } from "@react-pdf/renderer";

import shoulder from "../images/shoulder.png";

const Result = () => {
  const location = useLocation();
  const { name, age, gender, height, weight, result } = location.state || {};
  const bodyModel = gender === "Male" ? maleModel : femaleModel;
  const bodymob = gender === "Male" ? male_mob : female_mob;

  const [unit, setUnit] = useState("cm"); // State to manage the current unit

  // Function to convert cm to inches
  const convertToInch = (cmValue) => {
    return (cmValue / 2.54).toFixed(2); // Convert and round to 2 decimal places
  };

  // Function to format measurement based on the selected unit
  const formatMeasurement = (value) => {
    if (value === undefined || value === null) return "-";
    if (unit === "inch") {
      return convertToInch(value);
    }
    return value.toFixed(2);
  };

  // Toggle unit between cm and inch
  const toggleUnit = () => {
    setUnit((prevUnit) => (prevUnit === "cm" ? "inch" : "cm"));
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.firstsection}>
          <div className={styles.heading}>Measurements</div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Weight</th>
                <th>Height</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{name}</td>
                <td>{age} yrs</td>
                <td>{gender}</td>
                <td>{weight} kg</td>
                <td>{height} m</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.insidecontainer}>
          <div className={styles.rescon}>
            <table className={styles.table1}>
              <thead>
                <tr>
                  <th>Body Points</th>
                  <th>Measurement</th>
                  <th style={{ position: "static" }}>
                    Units
                    <button
                      onClick={toggleUnit}
                      className={styles.toggleButton}
                    >
                      {unit === "cm" ? "Switch to Inch" : "Switch to cm"}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Neck</td>
                  <td>{formatMeasurement(result?.Neck)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Shoulder</td>
                  <td>{formatMeasurement(result?.Shoulder)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Arm Length</td>
                  <td>{formatMeasurement(result?.Arm)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Chest/Bust</td>
                  <td>{formatMeasurement(result?.Chest)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Waist</td>
                  <td>{formatMeasurement(result?.Waist)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Hip</td>
                  <td>{formatMeasurement(result?.Hip)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Thigh</td>
                  <td>{formatMeasurement(result?.Thigh)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Knee</td>
                  <td>{formatMeasurement(result?.Knee)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Ankle</td>
                  <td>{formatMeasurement(result?.Ankle)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Inseam</td>
                  <td>{formatMeasurement(result?.Inseam)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Outseam</td>
                  <td>{formatMeasurement(result?.Outseam)}</td>
                  <td>{unit}</td>
                </tr>
                <tr>
                  <td>Foot Length</td>
                  <td>{formatMeasurement(result?.Foot)}</td>
                  <td>{unit}</td>
                </tr>
              </tbody>
            </table>
            <div className={styles.Button}>
              {/* <button className={styles.downloadButton}>Download</button> */}
              <PDFDownloadLink
                document={
                  <MyPdf
                    dataset={result}
                    name={name}
                    age={age}
                    gender={gender}
                    height={height}
                    weight={weight}
                  />
                }
                fileName={`${name}_measurements.pdf`}
                className={styles.downloadButton}
              >
                Download
              </PDFDownloadLink>
            </div>
          </div>
          <div className={styles.modelimg}>
            <img src={bodyModel} alt="Body Model" className={styles.image} />

            {/* <img src={neck} alt="Overlay" className={styles.neck} /> */}
            {/* <img src={chest} alt="Overlay" className={styles.chest} />
                        <img src={waist} alt="Overlay" className={styles.waist} />
                        <img src={hip} alt="Overlay" className={styles.hip} /> */}

            {/* <img src={shoulder} alt="Overlay" className={styles.shoulder} /> */}
          </div>
        </div>
      </div>
      <div className={styles.mobcontainer}>
        <div className={styles.heading}>Measurement</div>
        <div className={styles.con1}>
          <div className={styles.inputBox1}>
            <label>Name</label>
            <div className={styles.inputField1}>{name}</div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputBox}>
              <label>Age</label>
              <div className={styles.inputField}>{age} yrs</div>
            </div>
            <div className={styles.inputBox}>
              <label>Gender</label>
              <div className={styles.inputField}>{gender}</div>
            </div>
            <div className={styles.inputBox}>
              <label>Height</label>
              <div className={styles.inputField}>{height} m</div>
            </div>
            <div className={styles.inputBox}>
              <label>Weight</label>
              <div className={styles.inputField}>{weight} kg</div>
            </div>
          </div>
        </div>
        <div className={styles.modelimg}>
          <img src={bodymob} alt="Body Model" className={styles.image} />
        </div>
        <div className={styles.rescon}>
          <table className={styles.table1}>
            <thead>
              <tr>
                <th>Body Points</th>
                <th>Measurement</th>
                <th>
                  Units
                  <button onClick={toggleUnit} className={styles.toggleButton}>
                    {unit === "cm" ? "Switch to Inch" : "Switch to cm"}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Neck</td>
                <td>{formatMeasurement(result?.Neck)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Shoulder</td>
                <td>{formatMeasurement(result?.Shoulder)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Arm Length</td>
                <td>{formatMeasurement(result?.Arm)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Chest/Bust</td>
                <td>{formatMeasurement(result?.Chest)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Waist</td>
                <td>{formatMeasurement(result?.Waist)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Hip</td>
                <td>{formatMeasurement(result?.Hip)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Thigh</td>
                <td>{formatMeasurement(result?.Thigh)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Knee</td>
                <td>{formatMeasurement(result?.Knee)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Ankle</td>
                <td>{formatMeasurement(result?.Ankle)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Inseam</td>
                <td>{formatMeasurement(result?.Inseam)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Outseam</td>
                <td>{formatMeasurement(result?.Outseam)}</td>
                <td>{unit}</td>
              </tr>
              <tr>
                <td>Foot Length</td>
                <td>{formatMeasurement(result?.Foot)}</td>
                <td>{unit}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <PDFDownloadLink
          document={
            <MyPdf
              dataset={result}
              name={name}
              age={age}
              gender={gender}
              height={height}
              weight={weight}
            />
          }
          fileName={`${name}_measurements.pdf`}
          className={styles.downloadButton}
        >
          Download
        </PDFDownloadLink>{" "}
      </div>
    </div>
  );
};

export default Result;
