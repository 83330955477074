import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './SelfTest3.module.css';
const SelfTest3 = () => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  
  const postUserData = async (dataObj) => {
    try {
      console.log('Posting user data:', dataObj);
      const response = await axios.post('https://test.aivot.ai/auth/userResult', dataObj);

      if (response.status === 201) {
        console.log('User data successfully posted:', response.data);
      } else {
        console.error('Error posting user data:', response.status);
      }
    } catch (error) {
      console.error('Error posting user data:', error);
    }
  };

  const capturePhotoAndMeasure = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    if (imageSrc) {
      try {
        console.log('Captured image:', imageSrc);
        console.log('Sending request to measureModel API:', { height, weight, age, gender });

        const response = await axios.post('https://test.aivot.ai/measureModel', {
          height: height,
          weight: weight,
          age: age,
          gender: gender
        });

        console.log('Response from measureModel API:', response);

        if (response.data) {
          const result = response.data;
          console.log('Result:', result);
          const dataObj = {
            name,
            age,
            gender,
            height,
            weight,
            neck: result.Neck,      // Adding Neck measurement from the result
            shoulder: result.Shoulder,  // Adding Shoulder measurement from the result
            waist: result.Waist,    // You can include other measurements in the same way
            chest: result.Chest,
            hip: result.Hip,
            thigh: result.Thigh,
            knee:result.Knee,
            ankle:result.Ankle,
            inseam:result.Inseam,
            outseam:result.Outseam,
            arm_length:result.Arm,
            foot: result.Foot
          };
        
          // Save the data in the database after getting the result
          await postUserData(dataObj);

          // Navigate to the result page with all the data
          navigate('/result', {
            state: {
              name,
              age,
              gender,
              height,
              weight,
              result
            }
          });
        } else {
          throw new Error('API response was not ok');
        }
      } catch (error) {
        console.error('Error capturing photo or calling API:', error);
      }
    } else {
      console.error('Photo capture failed');
    }
  };

  return (
    <div>
      {/* <hr className={styles.line} /> */}
      <div className={styles.container}>
        <div className={styles.formSection}>
          <div className={styles.heading}>
            Take your body measurement in few seconds.
          </div>
          <br />
          <div className={styles.formGroup}>
            <div className={styles.label}>Name</div>
            <input
              type="text"
              placeholder="Name should not contain any special character, number"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Age</div>
            <input
              type="number"
              placeholder="Age should not contain any special character or any alphabets"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
            <div className={styles.verticalLine}></div>
            <span className={styles.unit}>Yrs</span>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Gender</div>
            <div className={styles.verticalLine}></div>
            <div className={styles.customSelect}>
              <select value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="" disabled selected hidden>
                  Select gender
                </option>
                <option>Male</option>
                <option>Female</option>
                {/* <option>Other</option> */}
              </select>
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Height</div>
            <input
              type="number"
              placeholder="Height should be enter in meters"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
            <div className={styles.verticalLine}></div>
            <span className={styles.unit}>Meters</span>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Weight</div>
            <input
              type="number"
              placeholder="Weight should be enter in kilogram"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <div className={styles.verticalLine}></div>
            <span className={styles.unit}>Kilogram</span>
          </div>
        </div>
        <div className={styles.captureSection}>
          <div className={styles.heading1}>Capture photo</div>
          <div className={styles.captureContainer}>
            <div className={styles.cameraFrame}>
              <Webcam
                audio={false}
                mirrored={true} 
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className={styles.cameraFeed}
              />
            </div>
           <button className={styles.captureButton} onClick={capturePhotoAndMeasure}>
              Capture
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfTest3;
