import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import img1 from '../../images/1.png';
import img2 from '../../images/2.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
import img5 from '../../images/5.png';

const TakeSpin = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { gender } = location.state || {};
  console.log("GeNDER",{gender});



  // D E S K T O P -------------------------------------------------------------------------------------------------------->
  const [isTransformed, setIsTransformed] = useState(true);
  const [orderArr, setOrderArr] = useState([0, 1, 2, 3, 4]);
  const [dragStartX, setDragStartX] = useState(null);

  const stylesArray = {
    0: "h-48 md:h-72 lg:h-48 w-28 md:w-44 lg:w-36 top-0 left-1/2 translate-x-[-50%] rotate-[0turn]",
    1: "h-40 md:h-60 lg:h-48 w-24 md:w-[9rem] lg:w-36 top-[20%]  left-[83%]   -translate-x-1/2 rotate-[10deg]   md:top-[30%] md:left-[82.5%]   lg:top-[100%] md:left-[77%] lg:left-[83%] lg:translate-x-[20%]   lg:rotate-[72.5deg]",
    2: "h-40 md:h-60 lg:h-48 w-24 md:w-[9rem] lg:w-36 top-[400%] left-[200%]  -translate-x-1/2 rotate-[144deg]  md:top-[400%] md:left-[200%]  lg:top-[400%] md:left-[150%] lg:left-[75%] lg:translate-x-[-10%]  lg:rotate-[144deg]  lg:opacity-100",
    3: "h-40 md:h-60 lg:h-48 w-24 md:w-[9rem] lg:w-36 top-[400%] left-[-100%] -translate-x-1/2 rotate-[-144deg] md:top-[400%] md:left-[-100%] lg:top-[400%] md:left-[-50%] lg:left-[25%] lg:translate-x-[-90%]  lg:rotate-[-144deg] lg:opacity-100",
    4: "h-40 md:h-60 lg:h-48 w-24 md:w-[9rem] lg:w-36 top-[20%]  left-[17%]   -translate-x-1/2 rotate-[-10deg]  md:top-[30%] md:left-[17.5%]   lg:top-[100%] md:left-[17%] lg:left-[17%] lg:translate-x-[-120%] lg:rotate-[-72.5deg]",
  };

  const tiltArray = {
    0: " top-0 left-1/2 translate-x-[-50%] ",
    1: " top-0 left-1/2 translate-x-[10%]   rotate-[30deg]  translate-y-[5%]  md:translate-y-[0%] md:translate-x-[-10%]  md:rotate-[40deg]",
    2: " top-0 left-1/2 translate-x-[-110%] rotate-[-30deg] translate-y-[5%]  md:translate-y-[0%] md:translate-x-[-90%]  md:rotate-[-30deg]",
    3: " top-0 left-1/2 translate-x-[40%]   rotate-[40deg]  translate-y-[10%] md:translate-y-[0%] md:translate-x-[20%]   md:rotate-[40deg]",
    4: " top-0 left-1/2 translate-x-[-140%] rotate-[-40deg] translate-y-[10%] md:translate-y-[0%] md:translate-x-[-120%] md:rotate-[-30deg]",
  };

  const divImages = [img1, img2, img3, img4, img5];

  const getDivStyle = (index) => {
    const baseStyle = `absolute bg-cover bg-center h-48 md:h-44 lg:h-48 w-28 md:w-32 lg:w-36 rounded-3xl md:rounded-[2em] lg:rounded-[3em] z-[${9 - index}] cursor-pointer transition-all ease-custom duration-[.7s] shadow-lg md:shadow-xl`;
    const positionStyle = stylesArray[orderArr[index]];
    const tiltedStyle = tiltArray[orderArr[index]];
    return `${baseStyle} ${isTransformed ? tiltedStyle : positionStyle}`;
  };

  const getBackgroundImage = (index) => ({
    backgroundImage: `url(${divImages[index]})`,
  });

  // L E F T  ---  R O T A T E
  function leftRotateArray(arr) {
    const newArr = [...arr];
    newArr.push(newArr.shift());
    setOrderArr(newArr);
  }

  // R I G H T  ---  R O T A T E
  function rightRotateArray(arr) {
    const newArr = [...arr];
    newArr.unshift(newArr.pop());
    setOrderArr(newArr);
  }

  const handleDragStart = (event) => {
    setDragStartX(event.clientX);
    const img = new Image();
    img.src = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="; // 1x1 pixel transparent GIF
    event.dataTransfer.setDragImage(img, 0, 0);
  };

  const handleDragEnd = (event) => {
    if (dragStartX === null) return;

    const currentX = event.clientX;
    if (currentX < dragStartX) {
      rightRotateArray(orderArr);
    } else if (currentX > dragStartX) {
      leftRotateArray(orderArr);
    }

    // Reset drag start position
    setDragStartX(null);
  };

  const rotate = (index) => {
    if (!isTransformed) {
      if (orderArr[index] === 4) {
        leftRotateArray(orderArr);
      } else if (orderArr[index] === 1) {
        rightRotateArray(orderArr);
      }
    }
  };

  const takeSpin = () => {
    setIsTransformed(false);
    // setIsTransformed(!isTransformed);
  };

  useEffect(() => {
    takeSpin()
  },[])

  // M O B I L E ----------------------------------------------------------------------------------------------------------------------->

  const [isTransformedMob, setIsTransformedMob] = useState(true);

  const redirectHome = () => {
    navigate('/home', { state: { gender } });
  }

  return (
    <div className={`flex min-h-screen justify-center items-center bg-cbg1 select-none`}>
      <div className={`relative xxs:hidden lg:flex flex-col justify-start items-center min-h-screen w-[100rem] px-4 lg:px-6 pb-12 overflow-hidden bg-${isTransformed ? 'cbg1' : 'cbg1'}`}>
        <div className="relative h-[10rem] min-w-full mt-10 lg:mt-5 mb-14 text-9xl lg:text-[10rem] whitespace-nowrap text-tbg1 tracking-wide cursor-pointer"
          onClick={takeSpin}
        >
          {/* {!isTransformed ? "Take a Spin" : "LETS GO"} */}
          <div className={`absolute top-0 left-1/2 -translate-x-1/2 transition-all ease-in-out duration-[3s] ${isTransformed ? 'opacity-0' : 'opacity-100'}`}>Take a Spin</div>
          <div className={`absolute top-0 left-1/2 -translate-x-1/2 transition-all ease-in-out duration-[3s] ${isTransformed ? 'opacity-100' : 'opacity-0'}`}>Lets Go</div>
        </div>
        <div className="relative h-56 min-w-full z-[1]">
          {orderArr.map((_, index) => (
            <div
              key={index}
              className={getDivStyle(index)}
              style={getBackgroundImage(index)}
              onClick={isTransformed ? null : () => rotate(index)}
              draggable={orderArr[index] === 0} // Enable drag only on the top div
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            ></div>
          ))}
        </div>
        <div className="w-[30rem] md:w-[25rem] lg:w-[45rem] xl:w-[56rem] my-5 text-2xl leading-relaxed tracking-widest text-center z-[15]">
          Capture precise body measurements for a perfect fit<br/>Upload selfie and input weigh & height for accurate measurement
        </div>
        <div className="min-w-fit">
          <button
            className="h-16 w-52 mt-5 rounded-md bg-tbg1 text-3xl text-white hover:scale-95 transition-all ease-in-out duration-500"
            onClick={redirectHome}
          >
            GET STARTED
          </button>
        </div>
      </div>
      
      {/* M O B I L E -----------------------------> */}
      
      <div className={`relative flex lg:hidden flex-col justify-center items-center min-h-screen w-full px-4 lg:px-6 pb-12 overflow-hidden bg-white`}>
        <div className="mih-h-screen min-w-full text-center mt-0 mb-10 md:mb-16 text-6xl md:text-9xl text-tbg1 tracking-wide cursor-pointer">
          AIVOT.AI
        </div>
        <div className="relative h-56 w-screen z-[2]">
          {orderArr.map((_, index) => (
            <div
              key={index}
              className={getDivStyle(index)}
              style={getBackgroundImage(index)}
              onClick={isTransformed ? null : () => rotate(index)}
              draggable={orderArr[index] === 0} // Enable drag only on the top div
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            ></div>
          ))}
        </div>
        <div className="min-w-fit z-[2]">
          <button
            className="h-16 w-56 mt-5 md:mt-28 rounded-md bg-tbg1 text-3xl text-white hover:scale-95 transition-all ease-in-out duration-500"
            onClick={redirectHome}
          >
            GET STARTED
          </button>
        </div>
        <div className="relative whitespace-nowrap h-[3rem] md:h-16 min-w-full my-5 md:my-14 text-5xl md:text-7xl text-tbg1 tracking-wide cursor-pointer z-[2]"
          onClick={takeSpin}
        >
          <div className={`z-[2] absolute top-0 left-1/2 -translate-x-1/2 transition-all ease-in-out duration-[3s] ${isTransformed ? 'opacity-0' : 'opacity-100'}`}>Take a Spin</div>
          <div className={`z-[2] absolute top-0 left-1/2 -translate-x-1/2 transition-all ease-in-out duration-[3s] ${isTransformed ? 'opacity-100' : 'opacity-0'}`}>Lets Go</div>
        </div>
        <div className="w-full px-0 md:px-14 text-center text-xl md:text-3xl leading-relaxed tracking-widest z-[2]">
          Capture precise<br/>body measurements for a perfect fitUpload selfie and input weigh & height for accurate measurement
        </div>
        <div className={`absolute top-[52%] md:top-[55%] translate-y-[-16%] rounded-full h-[50rem] w-[50rem] md:h-[70rem] md:w-[75rem] z-[1] ${isTransformed ? 'bg-mbg1' : 'bg-cbg3'}`}></div>
      </div>
    </div>
  );
};

export default TakeSpin;