import React from 'react';
import styles from './contact.module.css';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const navigate = useNavigate();

    const fsubmit = () => {
        // Display an alert with the thank you message
        alert('Thank you for reaching out!');
        navigate("/home");

    };

    return (
        <div className={styles.container}>
            <div className={styles.formSection}>
                <div className={styles.heading}>Contact Us</div>

                <br />
                <div className={styles.formgroup}>
                    <div className={styles.label}>Email ID</div>
                    <input
                        className={styles.inputemail}
                        type="text"
                        placeholder="Write your email"
                    />
                    <div className={styles.label}>Send us a message</div>
                    <input
                        className={styles.inputmessage}
                        type="text"
                        placeholder="We'd love to hear from you"
                    />
                </div>
                <div className={styles.btn}>
                    <button className={styles.submit} onClick={fsubmit}>
                    SUBMIT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Contact;
