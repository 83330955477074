import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../context/UserContext';

import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { setUser } = useContext(UserContext); // Get setUser from context


  const toggleShowHide = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(email, password)

      const response = await axios.post('https://test.aivot.ai/login_user/login', {
        email: email, 
        password: password
      });
      
      if (response.status === 200) {
        console.log(response.data);
        setUser({
          name:response.data.name,
          email: email,
          phone: response.data.phone,
          gender: response.data.gender,
        });
        navigate('/takespin', { state: { gender: response.data.gender } });
      } else if (response.status === 201) {
        alert('Invalid email or password');
      } else {
        console.log('Login failed', response.data.message);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  }

  const [emailMob, setEmailMob] = useState("");
  const [passwordMob, setPasswordMob] = useState("");
  const [isPasswordVisibleMob, setIsPasswordVisibleMob] = useState(false);

  const toggleShowHideMob = () => {
    setIsPasswordVisibleMob((prevState) => !prevState);
  };

  const handleSubmitMob = async (e) => {
    e.preventDefault();

    try {
      console.log(emailMob, passwordMob)

      const response = await axios.post('https://test.aivot.ai/login_user/login', {
        email:emailMob, 
        password:passwordMob
      });
      
      if (response.status === 200) {
        console.log(response.data);
        setUser({
          name: response.data.name,
          email: emailMob,
          phone: response.data.phone,
          gender: response.data.gender,
        });
        navigate('/takespin', { state: { gender: response.data.gender } });
        // navigate('/takespin');
      } else if (response.status === 201) {
        alert('Invalid email or password');
      } else {
        console.log('Login failed', response.data.message);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  }
  
  const redirectRegister = () => {
    navigate("/register")
  }

  return (
    <div className="flex relative min-h-screen min-w-full bg-cbg1 overflow-hidden text-center">
      {/* For Desktop */}
      <div className="xxs:hidden lg:flex flex-col items-center min-h-full w-full mx-5 md:mx-10 my-5 overflow-hidden">
        <div className="flex flex-col min-h-fit w-[25rem]">
          <div className="text-center text-tbg2 text-5xl font-semibold">Sign in</div>
          <div className="text-center mt-2 mb-4">Join the community</div>
          <div className="flex flex-col justify-center item-center h-fit px-8 xl:px-10 py-7 border border-tbg2 rounded-[3rem] bg-custom-linear-5 text-center">
            <form onSubmit={handleSubmit} className="">
              <div className="w-full flex flex-col items-start">
                <label
                  htmlFor="email"
                  className="flex text-lg leading-6 text-tbg1"
                >
                  Email ID
                </label>
                <div className="mt-2 w-full">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="w-full flex border-b border-tbg2 py-1.5 px-1 bg-transparent rounded-[0]
                              text-base tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col items-start mt-6">
                <label
                  htmlFor="password"
                  className="flex text-lg leading-6 text-tbg1"
                >
                  Password
                </label>
                <div className="mt-2 w-full relative">
                  <input
                    id="password"
                    name="password"
                    type={isPasswordVisible ? "text" : "password"}
                    required
                    autoComplete="password"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full flex border-b border-tbg2 py-1.5 pl-1 pr-10 bg-transparent rounded-[0]
                              text-base tracking-wide text-gray-900 placeholder:text-gray-400 ring-0 inset-0 focus:outline-none"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {isPasswordVisible && (
                    <svg 
                      onClick={toggleShowHide}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                    </svg>
                  )}
                  {!isPasswordVisible && (
                    <svg 
                      onClick={toggleShowHide}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center items-center mx-auto mt-10 py-3 bg-custom-linear-6 
                          text-2xl text-tbg2 leading-6 rounded-full shadow-custom-inout
                          transition duration-300 transform hover:scale-95
                          focus-visible:outline focus-visible:outline-2 
                          focus-visible:outline-offset-0 focus-visible:outline-tbg1"
                >
                  SUBMIT
                </button>
              </div>
            </form>
            <div className="text-sm text-tbg1 mt-2 leading-0">
              Don’t have an account?{" "}
              <a
                // href="#"
                className="underline cursor-pointer"
                onClick={redirectRegister}
              >
                Let’s create
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* For Mobile */}
      <div className="flex lg:hidden flex-col items-center min-h-full w-full mx-5 my-10 overflow-hidden z-[2]">
        <div className="flex flex-col items-center min-h-fit w-[97%] md:w-[30rem]">
          <div className="text-tbg2 text-5xl font-semibold">Sign in</div>
          {/* <div className="text-lg mt-2 mb-4">Join the community</div> */}
          <div className="flex flex-col justify-center item-center h-fit w-full mt-10 px-8 py-14 border-2 border-tbg2 rounded-[3rem] bg-custom-linear-5">
            <form onSubmit={handleSubmitMob} className="">
              <div className="w-full flex flex-col items-center">
                <label
                  htmlFor="_mob"
                  className="flex text-xl leading-6 text-tbg1"
                >
                  Email ID
                </label>
                <div className="mt-2 w-full">
                  <input
                    id="email_mob"
                    name="_mob"
                    type="email"
                    required
                    autoComplete="email"
                    className="w-full flex border-2 border-tbg2 py-2 px-3 bg-custom-linear-7 rounded-xl z-[5]
                              text-center text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setEmailMob(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full flex flex-col items-center mt-6">
                <label
                  htmlFor="password"
                  className="flex text-xl leading-6 text-tbg1"
                >
                  Password
                </label>
                <div className="mt-2 w-full relative">
                  <input
                    id="password_mob"
                    name="password"
                    type={isPasswordVisibleMob ? "text" : "password"}
                    required
                    autoComplete="password"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full flex border-2 border-tbg2 py-2 px-10 bg-custom-linear-7 rounded-xl z-[5]
                              text-center text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setPasswordMob(e.target.value)}
                  />
                  {isPasswordVisibleMob && (
                    <svg 
                      onClick={toggleShowHideMob}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 cursor-pointer text-tbg2"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                    </svg>
                  )}
                  {!isPasswordVisibleMob && (
                    <svg 
                      onClick={toggleShowHideMob}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-6 w-6 cursor-pointer text-tbg2"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center items-center mx-auto mt-10 py-3 bg-custom-linear-6 
                          text-2xl text-tbg2 leading-6 rounded-xl shadow-custom-inout z-[5]
                          transition duration-300 transform hover:scale-95
                          focus-visible:outline focus-visible:outline-2 
                          focus-visible:outline-offset-0 focus-visible:outline-tbg1"
                >
                  SUBMIT
                </button>
              </div>
            </form>
            <div className="text-lg text-tbg1 mt-10">
              Don’t have an account?{" "}
              <a
                // href="#"
                className="underline cursor-pointer"
                onClick={redirectRegister}
              >
                Let’s create
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Background elements */}
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[25vw] w-[25vw] top-[-8%] lg:top-[-15%] left-[84%] lg:left-[84%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[25vw] w-[25vw] top-[78%] lg:top-[67%] left-[-5%] lg:left-[-8%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[9vw]  w-[9vw]  top-[60%] md:top-[58%] lg:top-[45%] left-[8%] md:left-[10%] xl:left-[8%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[9vw]  w-[9vw]  top-[25%] md:top-[30%] lg:top-[38%] xl:top-[35%] left-[85%] md:left-[82%] xl:left-[80%] z-[1]"></div>
      <div className="opacity-0 lg:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[5vw]  w-[5vw]  top-[10%] lg:top-[10%] left-[20%] lg:left-[20%] z-[1]"></div>
      <div className="opacity-0 lg:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[5vw]  w-[5vw]  top-[75%] lg:top-[75%] left-[73%] lg:left-[73%] z-[1]"></div>

      
      <div className="absolute opacity-100 md:opacity-0 transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[47vw] w-[47vw] top-[0%] left-[-20%] z-[1]"></div>
      <div className="absolute opacity-100 md:opacity-0 transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[60vw] w-[60vw] top-[73%] left-[-20%] z-[1]"></div>
      <div className="absolute opacity-100 md:opacity-0 transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[75vw] w-[75vw] top-[55%] left-[100%] -translate-x-1/2 -translate-y-1/2 z-[1]"></div>
    </div>
  );
};

export default Login;