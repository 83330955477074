import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from "../context/UserContext"; 
const Profile = () => {
  const { user } = useContext(UserContext);

 
  return (
    <div className="flex relative min-h-screen min-w-full bg-custom-linear-11 overflow-hidden text-center">
      <div className="flex flex-col items-center min-h-full w-full md:mx-5 my-10 overflow-hidden z-[2]">
        <div className="flex flex-col min-h-fit w-[95%] md:w-[37rem]">
          <div className="text-tbg2 text-5xl font-semibold">Profile</div>
          <div className="flex flex-col justify-center item-center h-fit px-8 py-14 rounded-[3rem]">
            <div>
              <div className="w-full flex flex-col items-center">
                <label className="flex text-xl leading-6 text-tbg1 font-medium">Name</label>
                <div className="mt-2 w-full">
                  <div
                    className="w-full flex shadow-custom-inout py-1.5 px-3 bg-custom-linear-12 rounded-md z-[5] justify-center
                                  text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                  >
                    {user?.name || 'John'} 
                  </div>
                </div>
              </div>

            <div className="w-full flex flex-col items-center mt-10">
            <label className="flex text-xl leading-6 text-tbg1 font-medium">
                Email ID
            </label>
            <div className="mt-2 w-full relative">
                <div
                className="w-full flex shadow-custom-inout py-1.5 px-3 bg-custom-linear-12 rounded-md z-[5] justify-center
                                text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                >
              {user?.email || 'john@gmail.com'}
                </div>
            </div>
            </div>

              <div className="w-full flex flex-col items-center mt-10">
                <label className="flex text-xl leading-6 text-tbg1 font-medium">
                  Phone Number
                </label>
                <div className="mt-2 w-full relative">
                  <div
                    className="w-full flex shadow-custom-inout py-1.5 px-3 bg-custom-linear-12 rounded-md z-[5] justify-center
                                  text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                  >
                    {user?.phone || '9625985635'} 
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col items-center mt-10">
                <label className="flex text-xl leading-6 text-tbg1 font-medium">
                  Gender
                </label>
                <div className="mt-2 w-full relative">
                  <div
                    className="w-full flex shadow-custom-inout py-1.5 px-3 bg-custom-linear-12 rounded-md z-[5] justify-center
                                  text-lg tracking-wide text-gray-900 placeholder:text-gray-400 focus:outline-none"
                  >
                   {user?.gender || 'Male'} 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;