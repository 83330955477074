import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './nav.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from '../images/aivot_logo_rm.png';

// const Nav = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const location = useLocation(); // Get the current location

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };
//   const closeMenu = () => {
//     setIsOpen(false);
//   };

//   useEffect(() => {
//     const navName = document.getElementById('navName');
//     if (navName) {
//       // Update the nav name based on the current path
//       switch (location.pathname) {
//         case '/home':
//           navName.innerHTML = 'Home';
//           break;
//         case '/profile':
//           navName.innerHTML = 'Profile';
//           break;
//         case '/test':
//           navName.innerHTML = 'Test';
//           break;
//         case '/result':
//           navName.innerHTML = 'Result';
//           break;
//         case '/contact':
//           navName.innerHTML = 'Contact Us';
//           break;
//         default:
//           navName.innerHTML = '';
//       }
//     }
//   }, [location.pathname]);

//   const getLinkClass = (path) => {
//     return location.pathname === path ? `${styles.navLink} ${styles.activeLink}` : styles.navLink;
//   };

//   return (
//     <nav className={styles.navbar}>
//       <div className={styles.hamburger} onClick={toggleMenu}>
//         {isOpen ? ' ✖ ' : ' ☰ '}
//         <span id="navName" className={styles.navName}></span>
//       </div>

//       <div className={styles.logo}>
//         <img src={logo} alt="AIVOT.AI" className={styles.imglogo} />
//       </div>
//       <ul className={`${styles.navLinks} ${isOpen ? styles.showMenu : ''}`}>
//         <li><Link to="/home"    className={getLinkClass('/home')}    onClick={closeMenu}><i className="fas fa-home"></i> Home</Link></li>
//         <li><Link to="/profile" className={getLinkClass('/profile')} onClick={closeMenu}><i className="fas fa-user"></i> Profile</Link></li>
//         <li><Link to="/test"    className={getLinkClass('/test')}    onClick={closeMenu}><i className="fas fa-camera" /> Test</Link></li>
//         <li><Link to="/result"  className={getLinkClass('/result')}  onClick={closeMenu}><i className="fas fa-clipboard-list"></i> Result</Link></li>
//         <li><Link to="/contact" className={getLinkClass('/contact')} onClick={closeMenu}><i className="fas fa-envelope"></i> Contact Us</Link></li>
//         <li><Link to="/login"   className={getLinkClass('/login')}   onClick={closeMenu}><i className="fas fa-sign-in-alt"></i> Sign Out</Link></li>
//       </ul>
//     </nav>
//   );
// }

// export default Nav;

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const navName = document.getElementById('navName');
    if (navName) {
      // Update the nav name based on the current path
      switch (location.pathname) {
        case '/home':
          navName.innerHTML = 'Home';
          break;
        case '/profile':
          navName.innerHTML = 'Profile';
          break;
        case '/test':
          navName.innerHTML = 'Test';
          break;
        case '/result':
          navName.innerHTML = 'Result';
          break;
        case '/contact':
          navName.innerHTML = 'Contact Us';
          break;
        default:
          navName.innerHTML = '';
      }
    }
  }, [location.pathname]);

  const getLinkClass = (path) => {
    return location.pathname === path ? `${styles.navLink} ${styles.activeLink}` : styles.navLink;
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.hamburger} onClick={toggleMenu}>
        {isOpen ? ' ✖ ' : ' ☰ '}
        <span id="navName" className={styles.navName}></span>
      </div>

      <div className={styles.logo}>
        <img src={logo} alt="AIVOT.AI" className={styles.imglogo} />
      </div>
      <ul className={`${styles.navLinks} ${isOpen ? styles.showMenu : ''}`}>
        <li><Link to="/home"    className={getLinkClass('/home')}    onClick={closeMenu}><i className="fas fa-home"></i> Home</Link></li>
        <li><Link to="/profile" className={getLinkClass('/profile')} onClick={closeMenu}><i className="fas fa-user"></i> Profile</Link></li>
        <li><Link to="/test"    className={getLinkClass('/test')}    onClick={closeMenu}><i className="fas fa-camera" /> Test</Link></li>
        <li><Link to="/result"  className={getLinkClass('/result')}  onClick={closeMenu}><i className="fas fa-clipboard-list"></i> Result</Link></li>
        <li><Link to="/contact" className={getLinkClass('/contact')} onClick={closeMenu}><i className="fas fa-envelope"></i> Contact Us</Link></li>
        <li><Link to="/login"   className={getLinkClass('/login')}   onClick={closeMenu}><i className="fas fa-sign-in-alt"></i> Sign Out</Link></li>
      </ul>
    </nav>
  );
}

export default Nav;