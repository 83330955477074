import React, { useEffect, useState } from "react";
import  { useContext } from "react";

import { UserContext } from "../../context/UserContext"; 
import { useNavigate } from "react-router-dom";
import axios from "axios";
import cmplogo from "../../images/aivot_logo.png";
import show from '../../images/show.png'
import hide from '../../images/hide.png'

const Register = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);


  // const [emailError, setEmailError] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [cnfpswd, setCnfpswd] = useState('');
  const [gender, setGender] = useState('')
  
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const toggleShowHide = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    const email = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(email));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    const password = e.target.value;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
    setPasswordValid(passwordPattern.test(password));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, phone, password, phone, gender, gender)

    if (cnfpswd !== password) {
      alert('The password confirmation does not match')
    }
    else if ((emailValid && passwordValid)) {
      try {
        const response = await axios.post('https://test.aivot.ai/register_user/register', {
          name: name,
          email: email,
          phone: phone,
          username: name,
          password: password,
          gender: gender
      });

        if (response.status === 200) {
          console.log(response.data);
          console.log('Request successful');
          setUser({ name, email, phone, gender });

          navigate("/takespin",{ state: { gender } });
        }
        else if (response.status === 201) {
          alert('Email already exists')
        } else {
          console.log('Request failed', response.data.message);
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    }
  }
  
  const [nameMob, setNameMob] = useState('');
  const [emailMob, setEmailMob] = useState('');
  const [phoneMob, setPhoneMob] = useState('');
  const [passwordMob, setPasswordMob] = useState('');
  const [cnfpswdMob, setCnfpswdMob] = useState('');
  const [genderMob, setGenderMob] = useState('')

  const [isPasswordVisibleMob, setIsPasswordVisibleMob] = useState(false);
  const [emailValidMob, setEmailValidMob] = useState(false);
  const [passwordValidMob, setPasswordValidMob] = useState(false);


  const spel = document.getElementById('gender_mob')
  // spel.style.textAlign = 'center'

  const toggleShowHideMob = () => {
    setIsPasswordVisibleMob(prevState => !prevState);
  };

  const handleEmailChangeMob = (e) => {
    setEmailMob(e.target.value)
    const email = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidMob(emailPattern.test(email));
  };

  const handlePasswordChangeMob = (e) => {
    setPasswordMob(e.target.value)
    const password = e.target.value;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
    setPasswordValidMob(passwordPattern.test(password));
  };

  const handleSubmitMob = async (e) => {
    e.preventDefault();
    console.log(nameMob, emailMob, phoneMob, passwordMob, phoneMob, genderMob, genderMob)

    if (cnfpswdMob !== passwordMob) {
      alert('The password confirmation does not match')
    }
    else if ((emailValidMob && passwordValidMob)) {
      try {
        const response = await axios.post('https://test.aivot.ai/register_user/register', {
          name: nameMob,
          email: emailMob,
          phone: phoneMob,
          username: nameMob,
          password: passwordMob,
          gender: genderMob
      });
        if (response.status === 200) {
          console.log(response.data);
          console.log('Request successful');
          setUser({ 
            name: nameMob, 
            email: emailMob, 
            phone: phoneMob, 
            gender: genderMob 
          });

          navigate("/takespin",{ state: { gender } });
        }
        else if (response.status === 201) {
          alert('Email already exists')
        } else {
          console.log('Request failed', response.data.message);
        }
      } catch (error) {
        console.log('An error occurred:', error);
      }
    }
  }

  const redirectLogin = () => {
    navigate("/login");
  }

  return (
    <div className="relative flex min-h-screen min-w-full bg-cbg1 overflow-hidden text-center">
      {/* For Desktop */}
      <div className="flex xxs:hidden lg:flex flex-col items-center min-h-full w-full mx-5 md:mx-10 my-4 overflow-hidden z-[12]">
        <div className="flex flex-col min-h-fit w-[28rem]">
          <div className="text-tbg2 text-5xl font-semibold mb-5">Sign up</div>
          {/* <div className="mt-2 mb-4">Join the community</div> */}
          <div className="flex flex-col justify-center item-center h-fit px-8 xl:px-8 py-5 border border-tbg2 rounded-[3rem] bg-custom-linear-5">
            <form onSubmit={handleSubmit} className="">
              <div className="w-full flex flex-col items-start">
                <label htmlFor="name" className="flex text-lg leading-6 text-tbg1">
                  Name
                </label>
                <div className="mt-1.5 w-full">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    autoComplete="name"
                    pattern="^[A-Za-z\s]+$"
                    title="Name should contain alphabets only"
                    onChange={(e) => setName(e.target.value)}
                    className="w-full flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                  />
                </div>
              </div>
              <div className="mt-0.5 text-xs text-gray-500 text-start italic">
                Name should contain alphabets only. No special characters & no numbers are allowed
              </div>
              
              <div className="w-full flex flex-col items-start mt-4">
                <label htmlFor="email" className="flex text-lg leading-6 text-tbg1">
                  Email ID
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    title="must be a valid email"
                    className="w-full flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={handleEmailChange}
                  />
                  {emailValid && (
                    <svg 
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 text-green-600"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                    </svg>                  
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col items-start mt-4">
                <label htmlFor="number" className="flex text-lg leading-6 text-tbg1">
                  Phone Number
                </label>
                <div className="mt-1.5 w-full">
                  <input
                    id="number"
                    name="number"
                    type="number"
                    required
                    autoComplete="number"
                    pattern="^\d{1,13}$"
                    title="should contain numbers only"
                    className="w-full flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-0.5 text-xs text-gray-500 text-start italic">
                Phone number should be numberic
              </div>

              <div className="w-full flex flex-col items-start mt-4">
                <label htmlFor="gender" className="flex text-lg leading-6 text-tbg1">
                  Gender
                </label>
                <div className="mt-1.5 w-full">
                  <select
                    id="gender"
                    name="gender"
                    required
                    className="w-full flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="" disabled selected>Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>

              <div className="w-full flex flex-col items-start mt-4">
                <label htmlFor="password" className="flex text-lg leading-6 text-tbg1">
                  Set Password
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="password"
                    name="password"
                    type={isPasswordVisible ? "text" : "password"}
                    required
                    autoComplete="password"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full pr-12 flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={handlePasswordChange}
                  />
                  {isPasswordVisible && (
                    <svg 
                      onClick={toggleShowHide}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                    </svg>
                  )}
                  {!isPasswordVisible && (
                    <svg 
                      onClick={toggleShowHide}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  )}
                  {/* {passwordValid && (
                    <svg 
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 text-green-600"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                    </svg>                  
                  )} */}
                </div>
              </div>
              <div className="mt-0.5 text-xs text-gray-500 text-start italic">
                Password should contain atleast one uppercase alphabet, one lowercase alphabet, one special character & one number.
              </div>
              
              <div className="w-full flex flex-col items-start mt-4">
                <label htmlFor="conf_password" className="flex text-lg leading-6 text-tbg1">
                  Confirm Password
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="conf_password"
                    name="conf_password"
                    type={isPasswordVisible ? "text" : "password"}
                    required
                    autoComplete="password"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full pr-12 flex border-b border-tbg2 bg-transparent rounded-[0]
                              sm:text-md sm:leading-6 text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setCnfpswd(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className={`mt-0.5 text-xs text-red-500 text-start font-medium opacity-${warn ? '0' : '100'}`}>
                The password confirmation does not match
              </div> */}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center items-center mx-auto mt-4 py-3 bg-custom-linear-6 
                          text-2xl text-tbg2 leading-6 rounded-full shadow-custom-inout
                          transition duration-300 transform hover:scale-95
                          focus-visible:outline focus-visible:outline-2 
                          focus-visible:outline-offset-1 focus-visible:outline-tbg1"
                >
                  SUBMIT
                </button>
              </div>
            </form>
            <div className="text-sm text-tbg1 mt-2 leading-0">
              Have an account?{" "}
              <a
                href="#"
                className="underline"
                onClick={redirectLogin}
              >
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* For Mobile */}
      <div className="flex lg:hidden flex-col items-center min-h-full w-full mx-5 my-10 overflow-hidden z-[2]">
        <div className="flex flex-col min-h-fit w-[97%] md:w-[30rem]">
          <div className="text-tbg2 text-5xl font-semibold">Sign up</div>
          <div className="text-lg mt-2 mb-4">Join the community</div>
          <div className="flex flex-col justify-center item-center h-fit px-8 py-7 border-2 border-tbg2 rounded-[3rem] bg-custom-linear-5">
            <form onSubmit={handleSubmitMob} className="">
              <div className="w-full flex flex-col items-center">
                <label htmlFor="name" className="flex text-xl leading-6 text-tbg1">
                  Name
                </label>
                <div className="mt-1.5 w-full">
                  <input
                    id="name_mob"
                    name="name"
                    type="text"
                    required
                    autoComplete="name"
                    pattern="^[A-Za-z\s]+$"
                    title="Name should contain alphabets only"
                    className="w-full flex border-2 border-tbg2 py-2 px-3 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setNameMob(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-1.5 text-sm text-gray-500 text-start italic">
                Name should contain alphabets only. No special characters & no numbers are allowed
              </div>
              
              <div className="w-full flex flex-col items-center mt-4">
                <label htmlFor="email" className="flex text-xl leading-6 text-tbg1">
                  Email ID
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="email_mob"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    title="must be a valid email"
                    className="w-full flex border-2 border-tbg2 py-2 px-3 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={handleEmailChangeMob}
                  />
                  {emailValidMob && (
                    <svg 
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 text-green-600"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                    </svg>                  
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col items-center mt-4">
                <label htmlFor="number" className="flex text-xl leading-6 text-tbg1">
                  Phone Number
                </label>
                <div className="mt-1.5 w-full">
                  <input
                    id="number_mob"
                    name="number"
                    type="number"
                    required
                    autoComplete="number"
                    className="w-full flex border-2 border-tbg2 py-2 px-3 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setPhoneMob(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-1.5 text-sm text-gray-500 text-start italic">
                Phone number should be numberic
              </div>

              <div className="w-full flex flex-col justify-center items-center mt-4">
                <label htmlFor="gender" className="flex text-xl leading-6 text-tbg1">
                  Gender
                </label>
                <div className="mt-1.5 w-full justify-center items-center text-center">
                  <select
                    id="gender_mob"
                    name="gender"
                    required
                    style={{
                      display: 'hidden',
                      textAlign: 'center !important',
                      justifyContent: 'center !important',
                      alignItems: 'center',
                    }}
                    className="w-full flex justify-center items-center border-2 border-tbg2 py-[0.65rem] px-3 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setGenderMob(e.target.value)}
                  >
                    <option value="" disabled selected>Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>

              <div className="w-full flex flex-col items-center mt-4">
                <label htmlFor="password_mob" className="flex text-xl leading-6 text-tbg1">
                  Set Password
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="password_mob"
                    name="password_mob"
                    type={isPasswordVisibleMob ? "text" : "password"}
                    required
                    autoComplete="password"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full flex border-2 border-tbg2 py-2 px-10 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={handlePasswordChangeMob}
                  />
                  {/* <img
                    src={isPasswordVisible ? hide : show}
                    alt={isPasswordVisible ? "hide password" : "show password"}
                    onClick={toggleShowHide}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                  /> */}
                  {isPasswordVisibleMob && (
                    <svg 
                      onClick={toggleShowHideMob}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                    </svg>
                  )}
                  {!isPasswordVisibleMob && (
                    <svg 
                      onClick={toggleShowHideMob}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer text-tbg2 opacity-95 z-[6]"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    >
                      <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                  )}
                </div>
              </div>
              <div className="mt-1.5 text-sm text-gray-500 text-start italic">
                Password should contain atleast one uppercase alphabet, one lowercase alphabet, one special character & one number.
              </div>

              <div className="w-full flex flex-col items-center mt-4">
                <label htmlFor="conf_password_mob" className="flex text-xl leading-6 text-tbg1">
                  Confirm Password
                </label>
                <div className="mt-1.5 w-full relative">
                  <input
                    id="conf_password_mob"
                    name="conf_password_mob"
                    type={isPasswordVisibleMob ? "text" : "password"}
                    required
                    autoComplete="password"
                    // pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+"
                    className="w-full flex border-2 border-tbg2 py-2 px-3 bg-custom-linear-7 rounded-xl z-[5] 
                              text-center text-lg tracking-wide text-tbg1 placeholder:text-gray-400 focus:outline-none"
                    onChange={(e) => setCnfpswdMob(e.target.value)}
                  />
                  {/* <img
                    src={isPasswordVisible ? hide : show}
                    alt={isPasswordVisible ? "hide password" : "show password"}
                    onClick={toggleShowHide}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                  /> */}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center items-center mx-auto mt-4 py-3 bg-custom-linear-6 
                          text-2xl text-tbg2 leading-6 rounded-xl shadow-custom-inout
                          transition duration-300 transform hover:scale-95
                          focus-visible:outline focus-visible:outline-2 
                          focus-visible:outline-offset-1 focus-visible:outline-tbg1"
                >
                  SUBMIT
                </button>
              </div>
            </form>
            <div className="text-lg text-tbg1 mt-5">
              Have an account?{" "}
              <a
                href="#"
                className="underline"
                onClick={redirectLogin}
              >
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Background elements */}
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[25vw] w-[25vw] top-[-8%] lg:top-[-15%] left-[84%] lg:left-[84%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[25vw] w-[25vw] top-[78%] lg:top-[67%] left-[-5%] lg:left-[-8%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[9vw]  w-[9vw]  top-[60%] md:top-[58%] lg:top-[45%] left-[8%] md:left-[10%] xl:left-[8%] z-[1]"></div>
      <div className="opacity-0 md:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[9vw]  w-[9vw]  top-[25%] md:top-[30%] lg:top-[38%] xl:top-[35%] left-[85%] md:left-[82%] xl:left-[80%] z-[1]"></div>
      <div className="opacity-0 lg:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[5vw]  w-[5vw]  top-[10%] lg:top-[10%] left-[20%] lg:left-[20%] z-[1]"></div>
      <div className="opacity-0 lg:opacity-100 absolute transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[5vw]  w-[5vw]  top-[75%] lg:top-[75%] left-[73%] lg:left-[73%] z-[1]"></div>

      
      <div className="absolute flex md:hidden transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[47vw] w-[47vw] top-[0%] left-[-20%] z-[1]"></div>
      <div className="absolute flex md:hidden transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[60vw] w-[60vw] top-[73%] left-[-20%] z-[1]"></div>
      <div className="absolute flex md:hidden transition-all ease-out duration-500 rounded-full bg-custom-radial-2 h-[75vw] w-[75vw] top-[55%] left-[100%] -translate-x-1/2 -translate-y-1/2 z-[1]"></div>
    </div>
  );
};

export default Register;